.Container{
    height: 100vh;
    width: 100%;
     max-height: 1100px;
  position: relative;
  overflow: hidden;
}


.Wrapper{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.Slider{
   width: 100%;
   height: 100vh;
   overflow: hidden;
}

.SliderHandler{
    position: absolute;
    top: 0;
    left: 0;
     width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.SliderHandler::after{
    content: '';
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.4);
    z-index: 2;
    
}

.HomeSlide{
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.HomeImg{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
left: 0;
}

.ImgInfo{
    font-size: 3rem;
    z-index: 3;
    color: #fff;
    margin-left: 10rem;
    .HeroTitle{
        font-weight: 700;
    }
}

.ButtonContainer{
    position: absolute;
bottom: 50px;
right: 50px;
display: flex;
z-index: 10;
width: 30rem;
justify-content: space-around;
cursor: pointer;
.PrevButton{
      background: #000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
  height: 6rem;
    width: 6rem;
        fill: #fff;
    }
  
}
.NextButton{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    border-radius: 50%;
    svg{
        height: 6rem;
       width: 6rem;
       fill: #fff;
    }
    
}
}

.ButtonContainer:hover{
    .PrevButton:hover, .NextButton:hover{
        background: rgb(233, 169, 51);
        transition: all .3s ease-in-out;
    }
}


@media (max-width: 767.98px) {
    .ImgInfo{
    font-size: 3rem;
    z-index: 3;
    color: #fff;
    margin-left: 5rem;
    margin-top: 40rem;
    .HeroTitle{
        font-weight: 700;
    }
}
.ButtonContainer{
    right: 1rem;
    .PrevButton{
      background: #000;
    border-radius: 50%;
    svg{
  height:3rem;
    width: 3rem;
        fill: #fff;
    }
  
}
.NextButton{
    background: #000;
    border-radius: 50%;
    svg{
       height:3rem;
    width: 3rem;
       fill: #fff;
    }
    
}
}
 }