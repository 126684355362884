.footer {
  height: auto;
  background: #808080;
  color: #fff;
  padding-top: 20px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: grid;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(150px, auto);
  grid-column-gap: 10px;
  overflow: hidden;
  font-size: 1.5rem;
}

.footer > div p:first-child {
  font-weight: 700;
}

.footer > div p:not(:first-child) {
  font-weight: 200;
}

.footer > div:nth-child(3) {
  grid-column: 1/3;
  justify-self: center;
}

.footer > div:nth-child(5) p:not(:first-child) {
  font-size: 15px;
}

.footer_input {
  outline: none;
  border: 1px solid #808080;
  border-radius: 5px;
  padding: 0.6rem;
  width: 20rem;
  margin-top: 1rem;
}

.footer_input::placeholder {
  color: #000;
}

.download {
  width: 101px;
}

.footer > div:nth-child(6) {
  grid-column: 1/3;
  justify-self: center;
  /* align-self: center; */
}

.youtube,
.twitter,
.facebook {
  margin-left: 20px;
  padding: 0.5rem;
}

.facebook {
  margin-left: 0;
  border: none;
}

.instagram {
  background: #fff;
  border: 1px solid #fff;
  padding: 0.5rem;
  margin-left: 20px;
}

.footer > div:nth-child(4) {
  display: grid;
  grid-row-gap: 10px;
}

.footer > div {
  width: fit-content;
  justify-self: center;
}
 .footer > div:nth-child(6) img{
   height: 3rem;
 }

@media (min-width: 991.98px) {
  .footer > div {
    width: fit-content;
  }
}

@media (min-width: 767px) {
  .footer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(150px, auto);
    /* align-items: center; */
    padding-top: 4rem;
    padding-left: 50px;
    grid-column-gap: 15px;
    overflow: hidden;
  }
  .footer > div:nth-child(3) {
    grid-column: 3/3;
  }
  .footer > div:nth-child(6) {
    grid-row: 2/3;
    grid-column: 3/4;
  }
  .footer > div:nth-child(5) {
    grid-column: 4/4;
  }
}

@media (min-width: 576px) {
  .footer {
    justify-content: center;
  }
}