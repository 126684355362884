.Container{
    min-height: 100vh;
    padding: 5rem 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.Content{
    display: flex;
    justify-content: space-between;
    justify-self: center;
    align-self: center;
    gap: 2rem;
}

.Heading{
    text-align: center;
    h3{
        text-transform: uppercase;
        letter-spacing: 0.3rem;
        font-size: 3rem;
        font-weight: 700;
    }
    p{
        font-size: 1.5rem;
        font-weight: 400;
    }
}



.Card{
    border-radius: 3px;
    display: flex;
    gap: 2rem;
    padding: 2rem;
     flex: 0.3333333333333330;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
background-color: #fff;

    .CardIcon{
        flex: 0.1;
        svg{
            height: 10rem;
            width: 100%;
        }
    }
    .CardContent{
        flex: 0.9;
        .CardHeading{
            font-size: 2rem;
            font-weight: 600;
        }
        .CardBody{
            font-size: 1.5rem;
            font-weight: 300;
        }
    }
}

.Card:hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

@media (max-width: 991.98px) {
    .Container{
        padding: 5rem;
    }
    .Card{
        flex-direction: column;
        padding: 5rem 1rem;
        text-align: center;
    }
}

@media (max-width: 767.98px) { 
     .Heading{
            margin-top: 5rem;
            p{
                font-size: 1.5rem;
            }
        }
    .Content{
        flex-direction: column;
        margin-top: 13rem;
       
        .Card{
            max-width: 50rem;
        }
    }
 }