.Container {
  height: 100vh;
  width: 100%;
  max-height: 1100px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10rem;
  overflow: hidden;
  background: url(../images/hero.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .Caption {
    color: #fff;
    font-size: 3rem;
    z-index: 10;
    display: flex;
    flex: 0.8;
    flex-direction: column;
  }
}

.Container::after {
  content: "";
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.6);
  z-index: 2;
}

.Subtitle {
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: 400;
  width: 50rem;
}

.Cta {
  margin-top: 2rem;
  .SignUpBtn {
    font-size: 1.5rem;
    color: #fff;
    padding: 1rem 2rem;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 0.5rem;
  }
  .ContactUsBtn {
    font-size: 1.5rem;
    color: #000;
    padding: 1rem 2rem;
    background-color: #fff;

    border-radius: 0.5rem;
    margin-left: 5rem;
  }
}

.ScrollBtn {
  display: flex;
  flex: 0.3;
  justify-content: center;
  align-items: center;
  z-index: 10;
  height: 100vh;

  .Btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50rem;
    width: 100%;
    position: relative;
    a {
      z-index: 10000;
      .ArrowDown {
        color: #fff;
        cursor: pointer;
        padding: 4rem;
        height: 15rem;
        width: 20rem;
        stroke-width: 0.1px;
        animation: zoom-in-zoom-out 2.5s linear infinite;
      }
    }
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
@media (max-width: 991.98px) {
  .Container {
    flex-direction: column;
    justify-content: space-around;
    padding: 0 4rem;
    .Caption {
      margin-top: 10rem;
      justify-content: center;
      align-items: flex-start;
    }
  }
  .Cta {
    display: flex;

    .ContactUsBtn {
      margin-left: 5rem;
    }
  }
  .Rotate {
    top: -38.5rem;
  }
  .ScrollBtn{
.Btn{
      height: fit-content;
  }
  }
  
}

@media (max-width: 575.98px) {
  .Container {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 0 1rem;
    .Caption {
   
      justify-content: center;
      text-align: center;
      align-items: center;
      h3 {
        font-size: 3rem;
      }
    }
  }
  .Subtitle {
    margin-top: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    width: auto;
  }
  .Cta {
    display: flex;
    flex-direction: column;
    .SignUpBtn {
      margin-bottom: 2rem;
    }
    .ContactUsBtn {
      margin: 0;
    }
  }
  .Rotate, .ScrollBtn {
    display: none;
  }
}
