.container{
    min-height: 100vh;
    width: 100%;
    display: flex;
  
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
}

.about{
    display: flex;
    flex: .5;
    height: 100%;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about h3{
    font-weight: 300;
    letter-spacing: .3rem;
    font-size: 2rem;
    margin-bottom: 3rem;
}

.about p{
    font-weight: 200;
}

.container_image{
     display: flex;
    flex: .5;
    height: 100vh;
    background: url('../images/imageTwo.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
}


@media(min-width: 800px){
.about{
    display: flex;
    flex: .3;
}

.container_image{
    display: flex;
    flex: .7;
}
}

@media(min-width: 500px){
    .container{
  flex-direction: row;
    }
}