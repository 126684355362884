.container {

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
 
  flex-direction: column;
}

.banner{
  background: url('../images/imageTwo.jpg');
  background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    display: flex;
    flex: .6;
}

.contact_form{
  height: 65vh;
  display: flex;
  flex: .4;
  justify-content: center;
  align-items: center;
}

 form{
    border: 1px solid #caa472;
      border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 30rem;
}

.form_heading,
.form_subheading {
  text-align: center;
}

.form_heading{
    font-size: 1.5rem;
    color: #724409;
    margin-bottom: 1rem;
}

.input_container{
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
     position: relative;
}

.input_container label{
    font-size: 1rem;
    font-weight: 400;
       
}

.input_container input{
    width: 25rem;
    padding: .4rem .5rem;
    border-radius: 2pt;
    border: none;
    background-color: #ffffff;
    border: 1px solid #caa472 ;
    outline: none;

}

.send_message{
  padding: 1rem 2rem;
  font-size: 1rem;
  outline: none;
  color: #000;
  border-radius: 0.5rem;
  cursor: pointer;
}


.send_message:hover{
   background: #caa472;
   color: #fff;
    border-radius: 0.5rem;
    cursor: pointer;
   transition: all ease-in-out .3s;
}

@media screen and (max-width: 768px){

  .container{
    padding: 0 2rem;
  }
  .container form{
    margin-top: 5rem;
  }
}