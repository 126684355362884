@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  scroll-behavior: smooth;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

@media only screen and (max-width: 550px) {
  html {
    font-size: 60%;
  }
}

body {
  color: #000;
  background: #fff;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
      -ms-overflow-style: none;
    scrollbar-width: none;
}

body::-webkit-scrollbar{
    display: none;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}